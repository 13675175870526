import { useContext, useEffect, useState } from "react";
import Maximims from "./Maximims";
import Hour from "./Hour";
import Ranking from "./Ranking";
import MultiHour from "./MultiHour";
import Confrontations from "./Confrontations";
import Manutencao from "./manutencao";
import AuthContext from "../context/AuthContext";
import ExpressCup from "./ExpressCup";


export default function Analysis()
{
    const [maximimsPage, setMaximimsPage] = useState(true);
    const [hourPage, setHourPage] = useState(false);
    const [multihourPage, setMultihourPage] = useState(false);
    const [rankingPage, setRankingPage] = useState(false);
    const [confrontationsPage, setConfrontationsPage] = useState(false);
    const [expressCupPage, setExpressCupPage] = useState(false);

    const [manutencao, setManutencao] = useState(false);
    const {user} = useContext(AuthContext);

    function handlePage(page)
    {
        switch (page) {

            case "maximims":
                setConfrontationsPage(false);
                setMaximimsPage(true);
                setHourPage(false);
                setMultihourPage(false);
                setRankingPage(false);
                setExpressCupPage(false);
                break;

            case "hour":
                setConfrontationsPage(false);
                setMaximimsPage(false);
                setHourPage(true);
                setMultihourPage(false);  
                setRankingPage(false);
                setExpressCupPage(false);             
                break;      

            case "multihour":
                setConfrontationsPage(false);
                setMaximimsPage(false);
                setHourPage(false);
                setMultihourPage(true);  
                setRankingPage(false);
                setExpressCupPage(false);             
                break;    

            case "ranking":
                setConfrontationsPage(false);
                setMaximimsPage(false);
                setHourPage(false);
                setMultihourPage(false);
                setRankingPage(true);
                setExpressCupPage(false);           
                break;

            case "confrontations":
                setConfrontationsPage(true);
                setHourPage(false);
                setMultihourPage(false);
                setRankingPage(false);
                setMultihourPage(false);
                setMaximimsPage(false);
                setExpressCupPage(false);
                break;  

            case "expressCup":
                setConfrontationsPage(false);
                setHourPage(false);
                setMultihourPage(false);
                setRankingPage(false);
                setMultihourPage(false);
                setMaximimsPage(false);
                setExpressCupPage(true);
                break;                 
        
            default:
                break;
        }
    }

    if(manutencao)
    {
        if(user.id != 4)
        {
            return(
                <Manutencao/>
            )

        }else{
            return(
                <>
                    {maximimsPage && <Maximims 
                        handlePage={handlePage}
                    />}
                    {hourPage && <Hour 
                        handlePage={handlePage}
                    />}  
                    {multihourPage && <MultiHour
                        handlePage={handlePage}
                    />} 
                    {rankingPage && <Ranking
                        handlePage={handlePage}
                    />}  
                    {confrontationsPage && <Confrontations
                        handlePage={handlePage}
                    />}    
                    {expressCupPage && <ExpressCup
                        handlePage={handlePage}
                    />}          
                </>
            )
        }


        
    }else{

        return(
            <>
                {maximimsPage && <Maximims 
                    handlePage={handlePage}
                />}
                {hourPage && <Hour 
                    handlePage={handlePage}
                />}  
                {multihourPage && <MultiHour
                    handlePage={handlePage}
                />} 
                {rankingPage && <Ranking
                    handlePage={handlePage}
                />}  
                {confrontationsPage && <Confrontations
                    handlePage={handlePage}
                />}    
                {expressCupPage && <ExpressCup
                    handlePage={handlePage}
                />}          
            </>
        )
    }
    

}