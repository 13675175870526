class HeaderTableAssemble{

    static create(data, strategy)
    {
        switch (strategy) {
            case 1:
                return this.#bothTemasScoreYes(data)
                break;
            case 2:
                return this.#bothTemasScoreNot(data)
                break;    
            case 3:
                return this.#homeScore(data)
                break;     
            case 4:
                return this.#awayScore(data)
                break;
            case 5:
                return this.#over_0_5(data)
                break;    
            case 6:
                return this.#over_1_5(data)
                break;    
            case 7:
                return this.#over_2_5(data)
                break;
            case 8:
                return this.#over_3_5(data)
                break;                  
            case 9:
                return this.#under_0_5(data)
                break;    
            case 10:
                return this.#under_1_5(data)
                break;    
            case 11:
                return this.#under_2_5(data)
                break;
            case 12:
                return this.#under_3_5(data)
                break;  
            case 13:
                return this.#goals_0(data)
                break; 
            case 14:
                return this.#goals_1(data)
                break;  
            case 15:
                return this.#goals_2(data)
                break; 
            case 16:
                return this.#goals_3(data)
                break; 
            case 17:
                return this.#goals_4(data)
                break; 
            case 18:
                return this.#goals_5(data)
            case 19:
                return this.#gols_par(data)
                break; 
            case 20:
                return this.#gols_impar(data)
                break; 
            case 21:
                return this.#homeGoals1(data)
            case 22:
                return this.#homeGoals2(data)
                break; 
            case 23:
                return this.#homeGoals3(data)
                break;
            case 24:
                return this.#empateGoals1(data)
            case 25:
                return this.#empateGoals2(data)
                break; 
            case 26:
                return this.#empateGoals3(data)
                break;
            case 27:
                return this.#awayGoals1(data)
            case 28:
                return this.#awayGoals2(data)
                break; 
            case 29:
                return this.#awayGoals3(data)
                break;

            default:
                        
                return {
                    totalGoals: 0,
                    totalGreens: 0,
                    greensPercentage: "0%",
                    resultsByMinute: []
                };
                break;
        }
    }

    static #bothTemasScoreYes(data) {

        let totalGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }
    
        data.forEach(match => {

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);

            totalGoals += homeGoals + awayGoals;
    
            if (match.bothTeamsToScore === "Sim") {
                if (homeGoals > 0 && awayGoals > 0) {
                    totalGreens++;
                }
            }
    
            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);
    
            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (match.bothTeamsToScore === "Sim") {
                    if (homeGoals > 0 && awayGoals > 0) {
                        minuteEntry.totalGreens++;
                    }
                }
    
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        });
    
        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #bothTemasScoreNot(data) {

        let totalGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }
    
        data.forEach(match => {
            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;
    
            if (match.bothTeamsToScore === "Não") {
                if (homeGoals > 0 && awayGoals === 0 || homeGoals === 0 && awayGoals > 0) {
                    totalGreens++;
                }
            }
    
            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);
    
            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (match.bothTeamsToScore === "Não") {
                    if (homeGoals > 0 && awayGoals === 0 || homeGoals === 0 && awayGoals > 0) {
                        minuteEntry.totalGreens++;
                    }
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        });
    
        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #homeScore(data)
    {
        let totalGoals = 0;
        let totalHomeScore = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalHomeScore: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (homeGoals > 0) {
                totalHomeScore++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (homeGoals > 0) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #awayScore(data)
    {
        let totalGoals = 0;
        let totalAwayScore = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalAwayScore: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (awayGoals > 0) {
                totalAwayScore++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (awayGoals > 0) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


    static #over_0_5(data)
    {
        let totalGoals = 0;
        let totalOver_0_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_0_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) > 0) {
                totalOver_0_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) > 0) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalGreens > 0 ? (totalGreens / totalGreens) * 100 : 0;

        return {

            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            resultsByMinute
        };
    }


    static #over_1_5(data)
    {
        let totalGoals = 0;
        let totalOver_1_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_1_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);

            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) > 1) {
                totalOver_1_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) > 1) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #over_2_5(data)
    {
        let totalGoals = 0;
        let totalOver_2_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_2_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);

            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) > 2) {
                totalOver_2_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) > 2) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


    static #over_3_5(data)
    {
        let totalGoals = 0;
        let totalOver_3_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_3_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);

            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) > 3) {
                totalOver_3_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) > 3) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #under_0_5(data)
    {
        let totalGoals = 0;
        let totalOver_0_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_0_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 0) {
                totalOver_0_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 0) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


    static #under_1_5(data)
    {
        let totalGoals = 0;
        let totalOver_1_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_1_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) < 2) {
                totalOver_1_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) < 2) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #under_2_5(data)
    {
        let totalGoals = 0;
        let totalOver_2_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_2_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) < 3) {
                totalOver_2_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) < 3) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


    static #under_3_5(data)
    {
        let totalGoals = 0;
        let totalOver_3_5 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalOver_3_5: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) < 4) {
                totalOver_3_5++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) < 4) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_0(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 0) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 0) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_1(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 1) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 1) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_2(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 2) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 2) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_3(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 3) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 3) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_4(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) == 4) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) == 4) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #goals_5(data)
    {
        let totalGoals = 0;
        let totalGoals_0 = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGoals_0: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) > 5) {
                totalGoals_0++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) > 5) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


    static #gols_par(data)
    {
        let totalGoals = 0;
        let totalGolsPar = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGolsPar: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (!(homeGoals + awayGoals) % 2) {
                totalGolsPar++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (!(homeGoals + awayGoals) % 2) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #gols_impar(data)
    {
        let totalGoals = 0;
        let totalGolsPar = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalGolsPar: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if ((homeGoals + awayGoals) % 2) {
                totalGolsPar++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if ((homeGoals + awayGoals) % 2) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #homeGoals1(data)
    {
        let totalGoals = 0;
        let totalHomeGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalHomeGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals1(homeGoals, awayGoals)) {
                totalHomeGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals1(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals1(homeGoals, awayGoals) {
        if (
            (homeGoals === 1 && awayGoals === 0) ||
            (homeGoals === 2 && awayGoals === 0) ||
            (homeGoals === 2 && awayGoals === 1)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #homeGoals2(data)
    {
        let totalGoals = 0;
        let totalHomeGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalHomeGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals2(homeGoals, awayGoals)) {
                totalHomeGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals2(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    
    static checkGoals2(homeGoals, awayGoals) {
        if (
            (homeGoals === 3 && awayGoals === 0) ||
            (homeGoals === 3 && awayGoals === 1) ||
            (homeGoals === 4 && awayGoals === 0)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #homeGoals3(data)
    {
        let totalGoals = 0;
        let totalHomeGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalHomeGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (!this.checkGoals1(homeGoals, awayGoals) & !this.checkGoals2(homeGoals, awayGoals)) {
                totalHomeGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals1(homeGoals, awayGoals) & !this.checkGoals2(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static #empateGoals1(data)
    {
        let totalGoals = 0;
        let totalEmpateGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalEmpateGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals3(homeGoals, awayGoals)) {
                totalEmpateGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals3(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals3(homeGoals, awayGoals)
    {
        if (
            (homeGoals === 0 && awayGoals === 0)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #empateGoals2(data)
    {
        let totalGoals = 0;
        let totalEmpateGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalEmpateGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals4(homeGoals, awayGoals)) {
                totalEmpateGoals++;
                totalGreens++;
            }


            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals4(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals4(homeGoals, awayGoals)
    {
        console.log(`Home: ${homeGoals} | Away: ${awayGoals}`)

        if (
            (homeGoals === 1 && awayGoals === 1) ||
            (homeGoals === 2 && awayGoals === 2)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #empateGoals3(data)
    {
        let totalGoals = 0;
        let totalEmpateGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalEmpateGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals5(homeGoals, awayGoals)) {
                totalEmpateGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals5(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals5(homeGoals, awayGoals)
    {
        if (
            (homeGoals === 3 && awayGoals === 3) ||
            (homeGoals === 4 && awayGoals === 4)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }


    static #awayGoals1(data)
    {
        let totalGoals = 0;
        let totalAwayGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalAwayGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals6(homeGoals, awayGoals)) {
                totalAwayGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals6(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals6(homeGoals, awayGoals)
    {
        if (
            (homeGoals === 0 && awayGoals === 1) ||
            (homeGoals === 0 && awayGoals === 2) ||
            (homeGoals === 1 && awayGoals === 2)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #awayGoals2(data)
    {
        let totalGoals = 0;
        let totalAwayGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalAwayGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (this.checkGoals7(homeGoals, awayGoals)) {
                totalAwayGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (this.checkGoals7(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }

    static checkGoals7(homeGoals, awayGoals)
    {
        if (
            (homeGoals === 0 && awayGoals === 3) ||
            (homeGoals === 1 && awayGoals === 2) ||
            (homeGoals === 0 && awayGoals === 4)
        ) {
            return true;  // Indica que a condição foi satisfeita
        }
        return false;  // Indica que a condição não foi satisfeita
    }

    static #awayGoals3(data)
    {
        let totalGoals = 0;
        let totalAwayGoals = 0;
        let totalGreens = 0;
        const resultsByMinute = [];
        let totalMatches = data.length;
    
        // Inicializar todos os minutos com valores padrão
        for (let i = 0; i < 60; i++) {
            resultsByMinute.push({
                minute: i.toString().padStart(2, '0'),
                totalGoals: 0,
                totalAwayGoals: 0,
                totalGreens: 0,
                gamesCount: 0,
                greensPercentage: 0
            });
        }

        data.forEach(match=>{

            const [homeGoals, awayGoals] = match.scoreboard.split('-').map(Number);
            totalGoals += homeGoals + awayGoals;

            if (!this.checkGoals6(homeGoals, awayGoals) & !this.checkGoals7(homeGoals, awayGoals)) {
                totalAwayGoals++;
                totalGreens++;
            }

            const minute = match.time.split(':')[1];
            let minuteEntry = resultsByMinute.find(entry => entry.minute === minute);

            if (minuteEntry) {
                minuteEntry.totalGoals += homeGoals + awayGoals;
                minuteEntry.gamesCount++;
    
                if (!this.checkGoals6(homeGoals, awayGoals) & !this.checkGoals7(homeGoals, awayGoals)) {
                    minuteEntry.totalGreens++;
                }
                // Atualizar o percentual de greens para o minuto
                if (minuteEntry.gamesCount > 0) {
                    minuteEntry.greensPercentage = ((minuteEntry.totalGreens / minuteEntry.gamesCount) * 100).toFixed(0);
                } else {
                    minuteEntry.greensPercentage = 0;
                }
            }
        })

        const overallGreensPercentage = totalMatches > 0 ? ((totalGreens / totalMatches) * 100).toFixed(2) : 0;
        const overallRedsPercentage = totalMatches > 0 ? (((totalMatches - totalGreens) / totalMatches) * 100).toFixed(2) : 0;
    
        return {
            totalGoals,
            totalGreens,
            greensPercentage: overallGreensPercentage,
            redsPercentage: overallRedsPercentage,
            resultsByMinute
        };
    }


}



export default HeaderTableAssemble;