import { useState } from "react";
import ParamContext from "./ParamContext";

const ParamProvider = ({ children }) => {

    const [strategy, setStrategy] = useState(6);
    const [hourData, setHourData] = useState(23);
    const [showData, setShowData] = useState(1);
    const [selectedScores, setSelectedScores] = useState([]);

    return (
        <ParamContext.Provider value={{ strategy, setStrategy, hourData, setHourData, showData, setShowData, selectedScores, setSelectedScores  }}>
          {children}
        </ParamContext.Provider>
      );

}

export default ParamProvider;